<template>
  <main>
        <div class="counters">
      Il reste <div id="value"><span>1</span><span>0</span><span>0</span></div> participations disponibles
    </div>  
      <form class="siret">
          <label>Veuillez indiquer votre adresse email :</label>
          <input onfocus="this.placeholder = ''" type="email" placeholder="exemple : martin.dupont@gmail.com" id="email" v-model="email" name="email">
          <div class="button_wrapper">
              <button @click="checkForm">Suivant</button>
          </div>
      </form>
  </main>
</template>
<script>
import axios from "axios";
export default {
  name: "Jouez",
  data: function() {
    return {
      email: undefined,
    };
  },
  async mounted() {
    try {
      let { data } = await axios.get("https://api.lucien.ai/api/v2/counters/60350504c1d3380015e09fb0/husq");
      console.log(data.value)

        const obj = document.getElementById("value");
        const current_counter = data.value;
        var output = [],sNumber = current_counter.toString();
        for (var i = 0, len = sNumber.length; i < len; i += 1) {
            output.push("<span>"+sNumber.charAt(i)+"</span>");
          }
        obj.innerHTML = output.join('');
        this.animateValue(obj, 3000, data.value, 300);
    } catch(e) {

    }
  },
  methods: {
    animateValue : function (obj, start, end, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        var res = Math.floor(progress * (end - start) + start);
        var output = [],sNumber = res.toString();
        console.log(sNumber)
        for (var i = 0, len = sNumber.length; i < len; i += 1) {
            output.push("<span>"+sNumber.charAt(i)+"</span>");
          }
        obj.innerHTML = output.join('');
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    },    
  checkForm: async function (e) {
     e.preventDefault();
var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   if(re.test(this.email)) {
      try {
        let data = await axios.get(
          "https://api.lucien.ai/api/operations-custom/husqvarna-voucher/checkemail/" + this.email.trim()
        );
        
        if(data.status == 200) {
          if(data.data.count == 1) {
              this.$swal({
                type: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#C94330',
                text: 'Votre adresse email est déjà enregistrée. Un email vous a été envoyé.'
              })
          } else {
             localStorage.setItem('email', this.email.trim())
             this.$router.push("/form");
          }
        } else {
        }
      } catch(er) {
        this.$swal({
          type: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#C94330',
          text: 'Une erreur est survenue'
        })  
      }   
    } else {
        this.$swal({
          type: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#C94330',
          text: 'Cette adresse email est incorrecte'
        })    
    }
    return false
    },      
    getResult(result) {
      alert("Your sumbmission id is : "+result._id);
    },
    checkStep (step) {
      this.step = step
    }
  }
}
</script>